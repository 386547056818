import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Heading, Text } from "../bruin"

const ServicesPage = () => (
	<Layout>
		<SEO title="Home" />
		<Heading>Services</Heading>
		<Text>Grayhaber offers the following services:</Text>
		<ul>
			<li>Custom web design</li>
			<li>Custom web development</li>
		</ul>
	</Layout>
)

export default ServicesPage
