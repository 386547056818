import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Heading, Text } from "../bruin"

const WorkPage = () => (
	<Layout>
		<SEO title="Home" />
		<Heading>Work</Heading>
		<Text>Some of our projects include:</Text>
		<ul>
			<li>PetPass - </li>
			<li>Sealion House Flipping Software</li>
			<li>
				GHRBR Labs - Organized non-profit projects for developers to work in a team, with the goal of solving the "you need experience to
				get experience" problem.
			</li>
		</ul>
	</Layout>
)

export default WorkPage
